import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import '../../styles/formRenderer.scss';
import { useHistory } from 'react-router-dom';
import c from '../../helpers/constants';

import Button from '../../components/Button';
import { useValidation } from '../../hooks/ValidationHook';
import { isRequired } from '../../helpers/validators';

import { formatUrl } from '../../helpers/functions';

import { Alert } from 'react-bootstrap';

import ProposalInfo from '../../components/Proposal/ProposalInfo';

import {
  getActiveOffer,
  isFetchingOffers,
  isPostingOffer,
  postingOfferError,
  postingOfferFailed, postingOfferValidationErrors,
} from '../../selectors/Offer';
import { isFetchingContacts, isPostingContact } from '../../selectors/Contact';
import { useEditMode } from '../../hooks/EditModeHook';
import { fetchContacts } from '../../actions/Contact';
import { fetchSolutions } from '../../actions/Solution';
import { fetchActiveOffer, updateOffer } from '../../actions/Offer';
import Modal from '../../components/Modal';
import Stepper from '../../components/Stepper';
import assetGallery from '../../components/AssetGallery';
import TextArea from '../../components/TextArea';
import { getOfferItems, isFetchingOfferItems } from '../../selectors/OfferItem';
import { fetchOfferItems } from '../../actions/OfferItem';
import { DataTableParamsModel } from '../../types/Common';
import ItemsTable from '../../components/Offer/ItemsTable/ItemsTable';
import { isFetchingSolutions } from '../../selectors/Solution';
import useUserRoles from '../../hooks/userRoleHook';

function ReviewOffer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const activeOffer = useSelector(getActiveOffer);
  const [editMode, activeId] = useEditMode(c.APP_ROUTES.REVIEW_ESTIMATION);

  const isPosting = useSelector(isPostingOffer);
  
  const isPostingContacts = useSelector(isPostingContact);
  const isFetchingContactsState = useSelector(isFetchingContacts);
  const isFetchingOfferItemsState = useSelector(isFetchingOfferItems);
  const isFetchingSolutionsState = useSelector(isFetchingSolutions);
  const isFetchingOffersState = useSelector(isFetchingOffers);

  const isFetching = isFetchingContactsState || isFetchingOfferItemsState || isFetchingSolutionsState || isFetchingOffersState;

  const postingFailed = useSelector(postingOfferFailed);
  const postingError = useSelector(postingOfferError);
  const postingValidationErrors = useSelector(postingOfferValidationErrors);
  const offerItems = useSelector(getOfferItems);

  const formConfig = [
    { field: 'reEstimationNotes', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const statusCodes = activeOffer?.statusCodes[0];
  const rawStatusCode = statusCodes?.value ?? '';
  const latestStatusCode = rawStatusCode === 'Pending Offer' ? 'Pending Offer Acceptance'
    : rawStatusCode === 'Pending Estimation' ? 'Pending Quotation'
      : rawStatusCode;

  const latestStatusCodeNotes = statusCodes?.notes ?? '';
  const [reEstimationNotes, setReEstimationNotes] = useState('');

  const { isABBUser, isABBClientAdmin, isEMESuperAdmin } = useUserRoles();

  let steps = [
    'Pending Quotation',
    'Pending Offer Acceptance',
    'Ready to Ship',
    'In Transit',
    'Completed',
  ];

  if (latestStatusCode === 'Changes Requested') {
    steps = steps.map(step => step === 'Pending Offer Acceptance' ? 'Changes Requested' : step);
  }

  const activeStep = steps.indexOf(latestStatusCode);

  useEffect(() => {
    dispatch(fetchContacts());
    dispatch(fetchSolutions());
    if (editMode) {
      const dataTableParams1: DataTableParamsModel = {
        sortDirection: 'desc',
        paging: '100',
        searchField: 'offerId',
        searchWord: activeId,
      };
      dispatch(fetchActiveOffer(activeId));
      dispatch(fetchOfferItems(dataTableParams1));
    }
  }, [dispatch, editMode, activeId]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.DEAL_HUB);
    }
  }, [isPosting]);

  const onConfirmSubmit = () => {
    let newStatus = 'Pending Verification';

    if (editMode && activeOffer) {
      switch (latestStatusCode) {
        case 'Pending Offer Acceptance':
          newStatus = 'Offer Accepted';
          break;
        case 'Changes Requested':
          newStatus = 'Offer Accepted';
          break;
        default:
          newStatus = latestStatusCode; // If the status doesn't need to change
      }
    }

    const pmIds = activeOffer?.projectManagers.map(manager => manager.id);

    if (editMode) {
      setHasSubmitted(true);
      dispatch(updateOffer(
        activeId,
        activeOffer?.donorClientId ?? '',
        pmIds ?? '',
        activeOffer?.salesRepUserId ?? '',
        activeOffer?.shipmentContactId ?? '',
        activeOffer?.solutionId ?? '',
        activeOffer?.subject ?? '',
        activeOffer?.details ?? '',
        activeOffer?.date ?? '',
        activeOffer?.clientNumber ?? '',
        activeOffer?.currency ?? '',
        activeOffer?.pickupLocation ?? '',
        activeOffer?.collectionDate ?? '',
        activeOffer?.notesToEME ?? '',
        activeOffer?.notesToShippingProvider ?? '',
        newStatus,
      ));
    }
  };

  const onRequestChanges = async () => {
    if (triggerValidation()) {
      const pmIds = activeOffer?.projectManagers.map(manager => manager.id);

      if (editMode) {
        setHasSubmitted(true);
        dispatch(updateOffer(
          activeId,
          activeOffer?.donorClientId ?? '',
          pmIds ?? '',
          activeOffer?.salesRepUserId ?? '',
          activeOffer?.shipmentContactId ?? '',
          activeOffer?.solutionId ?? '',
          activeOffer?.subject ?? '',
          activeOffer?.details ?? '',
          activeOffer?.date ?? '',
          activeOffer?.clientNumber ?? '',
          activeOffer?.currency ?? '',
          activeOffer?.pickupLocation ?? '',
          activeOffer?.collectionDate ?? '',
          activeOffer?.notesToEME ?? '',
          activeOffer?.notesToShippingProvider ?? '',
          'Changes Requested',
          reEstimationNotes,
        ));
      }
    }
  };

  const onCancel = async () => {
    history.push(c.APP_ROUTES.DEAL_HUB);
  };


  const onDismissModal = () => {
    setShowModal(false);
    setConfirmSubmit(false);
  };

  const onSubmit = () => {
    setShowModal(true);
    setConfirmSubmit(true);
  };


  useEffect(() => {
    if (!isPostingContacts) {
      onDismissModal();
      dispatch(fetchContacts());
    }
  }, [isPostingContacts]);


  const salesRepItems = [
    { title: 'First Name', description: activeOffer?.salesRepUserFirstName },
    { title: 'Last Name', description: activeOffer?.salesRepUserLastName },
    { title: 'Email', description: activeOffer?.salesRepUserEmail },
    { title: 'Company Name', description: activeOffer?.projectManagerClientTitles?.includes('EME-Client')
      ? 'EME-Client'
      : activeOffer?.projectManagerClientTitles  }  ];

  const clientItems = [
    { title: 'Client', description: activeOffer?.clientTitle },
    { title: 'Email', description: activeOffer?.clientEmail },
    { title: 'Phone', description: activeOffer?.clientPhone },
    { title: 'Website', description: activeOffer?.clientWebsite },
  ];

  const projectManagers = activeOffer?.projectManagers || [];

  // Generate project manager items with titles
  const projectManagerItems = projectManagers.map((manager, index) => ({
    title: `Project Manager #${index + 1}`,
    items: [
      { title: 'First Name', description: manager.firstName },
      { title: 'Last Name', description: manager.lastName },
      { title: 'Email', description: manager.email },
      { title: 'Company Name', description: manager.companyName?.includes('EME-Client')
        ? 'EME-Client'
        : manager.companyName },
    ],
  }));

  const shipmentProviderItems = [
    { title: 'Company Name', description: activeOffer?.shipmentContactCompanyName },
    { title: 'Email', description: activeOffer?.shipmentContactEmail },
    { title: 'Phone Number', description: activeOffer?.shipmentContactNumber },
  ];

  const selfShippingItems = [
    { title: '', description: 'Client has agreed to handle shipping.' },
  ];

  const solutionProviderItems = [
    { title: 'Title', description: activeOffer?.solutionTitle },
    { title: 'Location', description: activeOffer?.solutionLocation?.split('~').pop() ?? activeOffer?.solutionLocation },
    { title: 'Email', description: activeOffer?.solutionContactEmail },
    { title: 'Phone Number', description: activeOffer?.solutionContactNumber },
  ];

  const shipmentStatuses = [
    'Ready to Ship', 'In Transit', 'Completed',
  ];

  const onReviewNotes = async () => {
    history.push(formatUrl(c.APP_ROUTES.CREATE_ESTIMATION, activeId));
  };

  //@ts-ignore
  const hasNonZeroActualRateItems = offerItems?.data.some(item => parseFloat(item.actualRate) !== 0.00);

  const getStatusMessage = () => {
    switch (latestStatusCode) {
      case 'Pending Quotation':
        return 'Your offer is currently under estimation. Please wait for further updates.';
      case 'Pending Offer Acceptance':
        return 'There is an offer pending for your proposal. Please review and either accept or request changes.';
      case 'Ready to Ship':
        return 'Your offer is ready for shipping.';
      case 'In Transit':
        return 'Your offer is currently in transit. Please monitor the shipping status.';
      case 'Completed':
        return 'The offer has been completed successfully.';
      case 'Changes Requested':
        return 'Your proposal has been reviewed and may have undergone some changes. Please check the details of the reviewed offer. If you are satisfied with the changes, you can accept the offer. Otherwise, you can request a re-estimation.';
      default:
        return 'Please review the current status of your offer and take the necessary actions.';
    }
  };


  return (
        <View title={!isFetching ? activeOffer?.clientNumber : ''}
              isLoading={isFetching || isPosting || !activeOffer}>
            <div className="page">
                {postingError && (
                    <Alert className="mt-3" variant="danger">
                        {postingError}
                    </Alert>
                )}
                <form>
                    <div className="category__container">
                        {steps.includes(latestStatusCode) && <div className="row justify-content-center">
                            <div className="col-md-10">
                                <Stepper
                                    steps={steps}
                                    activeStep={activeStep}
                                    hideMobileLabels={true}
                                    icon={
                                        latestStatusCode === 'Pending Offer Acceptance' ? assetGallery.iconPending
                                          : latestStatusCode === 'Ready to Ship' || latestStatusCode === 'In Transit' ? assetGallery.iconDelivery
                                            : latestStatusCode === 'Delivered' || latestStatusCode === 'Completed' ? assetGallery.iconCompleted
                                              : assetGallery.iconPending
                                    }
                                />
                                <div style={{ textAlign: 'center' }}>
                                    {getStatusMessage()}
                                </div>
                            </div>
                        </div>}
                    <ProposalInfo mainTitle={'Sales Representative Information'} items={salesRepItems}/>
                    <ProposalInfo mainTitle={'Client Information'} items={clientItems}/>
                        {projectManagerItems.map((pmInfo, index) => (
                            <ProposalInfo key={index} mainTitle={pmInfo.title} items={pmInfo.items} />
                        ))}
                        <ProposalInfo mainTitle={'Shipment Provider Information'}
                                  items={activeOffer?.shipmentContactId === 'selfShipping' ? selfShippingItems : shipmentProviderItems}/>
                    <ProposalInfo mainTitle={'Solution Provider Information'} items={solutionProviderItems}/>
                    <div className="estimation-container">
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="col-12 col-md-6 category__label">Offer Number #{activeOffer?.clientNumber}</div>
                            <div className="col-12 col-md-6 estimation-value mb-md-0 mb-3" style={{ textAlign: 'end' }}>OFFER DATE {activeOffer?.date}</div>
                        </div>
                        <div className='row'>
                            <div className="col-md-3 col-12 col-sm-6 mb-sm-0 mb-2">
                                <div className={'estimation-label'}>Pickup Location</div>
                                <div className={'estimation-value'}>{activeOffer?.pickupLocation?.split('~').pop() ?? activeOffer?.pickupLocation}</div>
                            </div>
                            <div className="col-md-3 col-12 col-sm-6 mb-md-0 mb-2">
                                <div className={'estimation-label'}>Collection Date</div>
                                <div className={'estimation-value'}>{activeOffer?.collectionDate}</div>
                            </div>
                            <div className="col-md-3 col-12 col-sm-6">
                                <div className={'estimation-label'}>Offer Details</div>
                                <div className={'estimation-value'}>{activeOffer?.details}</div>
                            </div>
                        </div>
                    </div>

                        <ItemsTable tableTitle={'Estimations'} rowsData={offerItems?.data} columnCount={6} currency={activeOffer?.currency ?? '€' } />

                        {hasNonZeroActualRateItems && <ItemsTable tableTitle={'EME Quotation'} rowsData={offerItems?.data}
                                     columnCount={4}
                                     showQRCodes={shipmentStatuses.includes(latestStatusCode) && isEMESuperAdmin}/>}
                      <div className="row mb-4">
                            <div className="col-sm-4 col-md-3 col-lg-2 col-12 mb-sm-0 mb-3">
                                <div className={'estimation-label'}>Notes for EME</div>
                                <div className={'estimation-value'}>{activeOffer?.notesToEME || 'None provided'}</div>
                            </div>
                            {activeOffer?.shipmentContactId != 'selfShipping' && <div className="col-sm-4 col-md-3 col-lg-2 col-12">
                                <div className={'estimation-label'}>Notes for Shipment</div>
                                <div className={'estimation-value'}>{activeOffer?.notesToShippingProvider}</div>
                            </div>}
                        </div>

                        <Modal show={showModal} title={!confirmSubmit ? 'REQUEST RE-ESTIMATION' : 'CONFIRMATION'} onHide={onDismissModal}>
                            {!confirmSubmit ? <TextArea v={v} err={err}
                                       name="reEstimationNotes"
                                       id="reEstimationNotes"
                                       label="Notes"
                                       required
                                       value={reEstimationNotes}
                                       onChange={setReEstimationNotes}
                                       disabled={isPosting || isFetching}
                                       onBlur={setReEstimationNotes}
                                       placeholder="Insert your re-estimation notes"
                            /> : <div>Are you sure you wish to accept the current estimation as is?</div>}
                            <div className="mt-3 form-renderer-button-container">
                                <Button title="Cancel" disabled={hasSubmitted} onClick={() => onDismissModal()}/>
                                <Button title="Confirm" disabled={!confirmSubmit ? !isFormValid() : false} onClick={confirmSubmit ? () => onConfirmSubmit() : onRequestChanges}/>
                            </div>
                        </Modal>
                        {(latestStatusCode === 'Changes Requested' && latestStatusCodeNotes && isEMESuperAdmin) && <div className="reEstimation-container col-12 col-md-3 col-sm-6">
                            <div className="col-md-12">
                                <div className={'estimation-label estimation-label-strong'}>RE-ESTIMATION REQUEST MESSAGE</div>
                                <div className={'estimation-label'}>{latestStatusCodeNotes}</div>
                                <Button title="Review" disabled={isFetching} onClick={onReviewNotes} isReview={true} />
                            </div>
                        </div>}

                        {((isABBClientAdmin || isABBUser || isEMESuperAdmin) && latestStatusCode === 'Pending Offer Acceptance') && <div className="offer-button-container">
                            <Button title="Back" disabled={hasSubmitted} onClick={onCancel}/>
                            <div className="offer-right-buttons">
                                <Button variant='black' title="Request Re-estimation" onClick={() => setShowModal(true)}/>
                                <Button title="Accept" onClick={onSubmit}/>
                            </div>
                        </div>}
                    </div>
                </form>
            </div>
        </View>

  );
}


export default ReviewOffer;
